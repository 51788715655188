<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div style="margin: 0 24px; padding-bottom: 24px;">
    <MainView />
  </div>
</template>

<script>
import MainView from './components/MainView.vue'

export default {
  name: 'App',
  components: {
    MainView
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 60px;
}

body {
  height: auto !important;
}
</style>
