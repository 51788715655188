<template>
  <div class="outer-container">
    <div class="inner-container">
      <h1><em>E. coli</em> Promoter Generation and Strength Prediction Platform</h1>
      <a-steps style="padding-left: 24px;padding-right: 24px;padding-bottom: 12px;" :current="current"
        :items="items"></a-steps>
      <div class="steps-content">
        <div v-if="steps[current].content == 'init-params'">
          <!-- info-params -->
          <StepInputParam @updateParamData="onUpdateParamData" />
        </div>
        <div v-if="steps[current].content == 'result'">
          <StepResult :paramData="initParam" @updateEnablePrev="onUpdateEnablePrev" />
        </div>
      </div>
      <div class="steps-action">
        <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
        <a-button v-if="current > 0" :disabled="!enablePrev" style="margin-left: 8px" @click="prev">Previous</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { message } from 'ant-design-vue'

import StepInputParam from './StepInputParam.vue'
import StepResult from './StepResult.vue'

const current = ref(0)
const next = () => {
  current.value++
}
const prev = () => {
  current.value--
}
const steps = [
  {
    title: 'Params',
    content: 'init-params',
  },
  {
    title: 'Result',
    content: 'result',
  },
];
const items = steps.map(item => ({
  key: item.title,
  title: item.title,
}));

export default {
  name: 'MainView',
  setup() {
    return {
      current,
      steps,
      items,
      next,
      prev,
      message,
    }
  },
  components: {
    StepInputParam,
    StepResult,
  },
  methods: {
    onUpdateParamData(data) {
      this.initParam = data
    },
    onUpdateEnablePrev(data) {
      this.enablePrev = data
    }
  },
  data: () => ({
    initParam: {},
    enablePrev: true
  }),
}
</script>
<style scoped>
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  /* text-align: center; */
  padding-top: 16px;
}

.steps-action {
  margin-top: 24px;
}

.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.inner-container {
  width: 100%;
  max-width: 800px;
  padding: 24px;
}
</style>