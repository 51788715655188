<template>
  <div>
    <a-form :model="paramData" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Promoter number">
        <a-row>
          <a-col :span="12">
            <a-slider v-model:value="paramData.promoterNumber" :min="100" :max="5000" />
          </a-col>
          <a-col :span="4">
            <a-input-number v-model:value="paramData.promoterNumber" :min="100" :max="5000" style="margin-left: 16px" />
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 14,
};


export default {
  name: 'StepInputParam',
  setup() {
    return {
      labelCol,
      wrapperCol,
    }
  },
  emits: ['updateParamData'],
  methods: {
  },
  data: () => ({
    paramData: {
      promoterNumber: 100,
    },
  }),
  watch: {
    paramData: {
      deep: true,
      handler(newValue) {
        console.log('updateParamData', newValue)
        this.$emit('updateParamData', newValue)
      },
      immediate: true,
    }
  },
  beforeMount() {
    this.$emit('updateParamData', this.paramData);

    this.paramData.promoterNumber = 100
  },
  created() {
    this.$emit('updateParamData', this.paramData);
  },
}
</script>
<style scoped></style>
